"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var framer_motion_1 = require("framer-motion");
var React = __importStar(require("react"));
function LoadingDots(_a) {
    var containerClassName = _a.containerClassName, innerContainerClassName = _a.innerContainerClassName, dotClassName = _a.dotClassName;
    return (React.createElement("span", { className: "inline-flex min-h-[1em] items-center justify-center align-middle ".concat(containerClassName) },
        React.createElement("span", { className: "flex gap-1 ".concat(innerContainerClassName) }, [0, 1, 2].map(function (i) { return (React.createElement(framer_motion_1.motion.span, { key: i, className: "h-2 w-2 rounded-full bg-gray-500 ".concat(dotClassName), animate: {
                opacity: [0.3, 1, 0.3],
            }, transition: {
                duration: 0.8,
                repeat: Infinity,
                ease: "easeInOut",
                delay: i * 0.1, // Stagger effect
            } })); }))));
}
exports.default = LoadingDots;
