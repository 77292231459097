"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PhiaCircleIconGray = void 0;
var React = __importStar(require("react"));
var util_1 = require("../util");
var PhiaCircleIconGray = function (_a) {
    var className = _a.className, _b = _a.width, width = _b === void 0 ? 20 : _b, _c = _a.height, height = _c === void 0 ? 20 : _c, props = __rest(_a, ["className", "width", "height"]);
    return (React.createElement("svg", __assign({ className: (0, util_1.customCN)("fill-black opacity-100 ", className), width: width, height: height, viewBox: "0 0 21 20", xmlns: "http://www.w3.org/2000/svg" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M10.5052 1.60156C5.90284 1.60156 2.17188 5.33252 2.17188 9.9349C2.17188 14.5373 5.90284 18.2682 10.5052 18.2682C15.1076 18.2682 18.8385 14.5373 18.8385 9.9349C18.8385 5.33252 15.1076 1.60156 10.5052 1.60156ZM14.2996 7.83264C14.2996 6.33913 13.5723 5.28717 12.0527 5.28717C11.6466 5.28717 11.2032 5.36159 10.759 5.4862C10.7449 5.49014 10.7316 5.47777 10.7347 5.46348L10.899 4.68505C10.9029 4.66647 10.8844 4.65111 10.8668 4.65844L9.46172 5.24634C9.4474 5.25233 9.43695 5.26498 9.43377 5.28017L9.25441 6.13713C9.24998 6.15827 9.23692 6.1766 9.21844 6.18779C8.91179 6.37349 8.61093 6.5829 8.31187 6.816C8.29881 6.82619 8.2937 6.84356 8.29913 6.85921L8.3548 7.01956C8.36265 7.04218 8.38893 7.05234 8.40999 7.04096C8.65099 6.91077 8.88152 6.79451 9.10713 6.69216C9.1216 6.68559 9.13732 6.69834 9.13396 6.71387C8.58747 9.23956 8.14308 11.2946 7.58735 13.868C7.5795 13.9043 7.57191 13.9395 7.56455 13.9737C7.37212 14.8672 7.33743 15.0283 6.94294 15.083C6.93087 15.0851 6.9212 15.0945 6.9192 15.1066L6.90372 15.1854C6.90136 15.1998 6.91243 15.2128 6.92698 15.2128H9.13373C9.14527 15.2128 9.15512 15.2045 9.15699 15.1931L9.16895 15.12C9.17113 15.1067 9.16142 15.0943 9.14809 15.0922C8.72495 15.0594 8.79296 14.7388 8.94648 14.015C8.95694 13.9657 8.96781 13.9144 8.97894 13.8613C9.29336 12.3607 9.30367 12.3034 9.30394 12.3019L9.30396 12.3017C9.30844 12.2805 9.33076 12.2683 9.35132 12.2753C9.62549 12.3687 10.0364 12.4041 10.2475 12.4041C12.7019 12.4041 14.2996 10.0664 14.2996 7.83264ZM10.4943 12.0022C11.8296 12.0022 12.9617 9.96253 12.9617 7.94953C12.9617 6.67679 12.4685 6.01386 11.4295 6.01386C11.176 6.01386 10.9242 6.05098 10.6218 6.13727C10.608 6.1412 10.5976 6.15252 10.5947 6.16655L9.71501 10.3392C9.57899 10.9844 9.55366 12.0022 10.4943 12.0022Z", fill: "#757575" })));
};
exports.PhiaCircleIconGray = PhiaCircleIconGray;
exports.default = exports.PhiaCircleIconGray;
